import React, {FC, useContext} from "react";
import { useNavigate } from 'react-router-dom'
import { DataStoreContext, DataStoreProvider } from "./dataStore";


const PageLayout: React.FC = () => {
  const navigate = useNavigate()
  const { openedAt, set } = useContext(DataStoreContext)

  const onChangeOpenedAt = (openedAt: number) => {
    set({ openedAt })
  }

  return (
    <>
      <h1 className="">Hook examples</h1>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse rem saepe commodi aliquam eos. Quibusdam, eveniet dolore. Explicabo, tenetur reiciendis.</p>
      <p>Opened at: {openedAt}</p>
      <form>
        <input 
          type={'number'} 
          onChange={e => {
            onChangeOpenedAt(parseInt(e.target.value))
          }}
        />
        <button className="btn" onClick={() => navigate('/')}>Back to list</button>
      </form>
      <button className="btn" onClick={() => navigate('/')}>Back to list</button>
    </>
  )
}

export const HooksPage: FC = () => {
    return (
      <DataStoreProvider openedAt={Date.now()}>
        <PageLayout />
      </DataStoreProvider>
    )
}