import React, { useRef } from "react";
import { TodoFormProps } from "./types";

export const TodoForm: React.FC<TodoFormProps> = props => {
  const ref = useRef<HTMLInputElement>(null)

  const onKeyUpHandler = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      props.onAdd(ref.current!.value)
      ref.current!.value = ''
    }
  }

  return (
    <div className="input-field mt2">
      <input
        ref={ref}
        type="text"
        id="title"
        placeholder="Input task name"
        onKeyUp={onKeyUpHandler}
      />
      <label htmlFor="title" className="active">
        Input task name
      </label>
    </div>
  );
};
