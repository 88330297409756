import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { Navbar } from "../../components/Navbar/Navbar";
import { AboutPage } from "../About/AboutPage";
import { TodosPage } from "../Todos/TodosPage";
import { HooksPage } from "../Hooks/HooksPage";
import { LeetCodePage } from "../LeetCode/LeetCode";
import "./App.css";

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<TodosPage />}/>
          <Route path="about" element={<AboutPage />} />
          <Route path="hooks" element={<HooksPage />} />
          <Route path="leetcode" element={<LeetCodePage />} />
        </Routes>
      </div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </BrowserRouter>
  );
};

export default App;
