import React from "react";
import { runPascalTriangle } from "./exercises/runPascalTriangle";

export const LeetCodePage: React.FC = () => {
  const task1 = () => {
    runPascalTriangle(0)
    runPascalTriangle(1)
    runPascalTriangle(2)
    runPascalTriangle(3)
    runPascalTriangle(4)
    runPascalTriangle(5)
  }

  return (
    <>
      <button className="btn waves-effect waves-light" type="submit" name="action" onClick={task1}>Pascal Triangle
        <i className="material-icons right">play_arrow</i>
      </button>
    </>
  )
}
