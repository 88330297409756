import React, { FC, createContext, useState, useEffect } from 'react'
import { DataStore } from '../../../types/store'
import { STORE_LOCAL_STORAGE_KEY } from '../../../utils/constants'

export const DataStoreContext = createContext<DataStore>({} as DataStore)

const saveInLocalStorage = (store: DataStore) => {
  try {
    localStorage.setItem(STORE_LOCAL_STORAGE_KEY, JSON.stringify(store))
    return store
  } catch (e) {
    return []
  }
}

type Props = {
  openedAt: number
  children: JSX.Element
}

const createDataStore = (
  openedAt: number,
  set: (dataStore: Partial<DataStore>) => void,
): DataStore => {
  return {
    openedAt,
    set,
  }
}

const loadLocalStorage = (): DataStore => {
  const rawState = localStorage.getItem(STORE_LOCAL_STORAGE_KEY)
  try {
    const state = rawState ? JSON.parse(rawState) : {}
    return state
  } catch (e) {
    localStorage.removeItem(STORE_LOCAL_STORAGE_KEY)
    throw e
  }
}

export const DataStoreProvider: FC<Props> = ({ openedAt, children }) => {
  const set = (dataStore: Partial<DataStore>) => {
    setDataStore(x => {
      const clone = { ...x, ...dataStore }
      saveInLocalStorage(clone)
      return clone
    })
  }

  const [dataStore, setDataStore] = useState<DataStore>(
    createDataStore(openedAt, set),
  )

  useEffect(() => {
    setDataStore(x => ({ ...x, ...loadLocalStorage() }))
  }, [])

  return (
    <DataStoreContext.Provider value={dataStore}>
      {children}
    </DataStoreContext.Provider>
  )
}
