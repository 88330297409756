import React from "react";
import { NavLink } from "react-router-dom";

export const Navbar: React.FC = () => (
  <nav>
    <div className="nav-wrapper purple darken-1 px1">
      <NavLink to="/" className="brand-logo">
        React App
      </NavLink>
      <ul id="nav-mobile" className="right hide-on-med-and-down">
        <li>
          <NavLink to="/">Todo list</NavLink>
        </li>
        <li>
          <NavLink to="/about">About us</NavLink>
        </li>
        <li>
          <NavLink to="/hooks">Hooks</NavLink>
        </li>
        <li>
          <NavLink to="/leetcode">LeetCode</NavLink>
        </li>
      </ul>
    </div>
  </nav>
);
