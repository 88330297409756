import React from "react";
import { useNavigate } from 'react-router-dom'

export const AboutPage: React.FC = () => {
  const navigate = useNavigate()
    return (
      <>
        <h1 className="">About us</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse rem saepe commodi aliquam eos. Quibusdam, eveniet dolore. Explicabo, tenetur reiciendis.</p>
        <button className="btn" onClick={() => navigate('/')}>Back to list</button>
      </>
    )
}