export const runPascalTriangle = (rowIndex: number): number[] => {
    let output: number[] = []
    for (let i = 0; i <= rowIndex; i++){
        const currentOutput = []
        for (let j = 0; j <= i; j++){
            if (j === 0 || j ===  i) {
                currentOutput.push(1)
                continue
            }
            currentOutput.push((output[j-1]+output[j]))
        }
        output = [...currentOutput]
    }
    return output
  }
  